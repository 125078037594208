
/** Plane Landing **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data, preloadData;

class IntroPage1 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[1];
    preloadData = globalState.assets[2];
    
    //refs
    this.planeLandingEndLoopVideoRef = this.planeLandingVideoRef = this.fullscreenBg = null;

  }

  state = {
    textVisible: false,
  }


  componentDidMount = () => {

    this.planeLandingVideoRef.playVideo();

    setTimeout(()=>{
      //hide NFB header
      globalState.hideHeader();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
    positionText(this.refs.text2Ref, "left", "top", globalState.textPosition.leftTop);
    positionText(this.refs.text3Ref, "right", "top", globalState.textPosition.rightTop);
    positionText(this.refs.text4Ref, "right", "bottom", globalState.textPosition.rightBottom);


    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0, delay: 2.0 });
    gsap.to(this.refs.text2Ref, 0.15, { autoAlpha: 1.0, delay: 4.0 });
    gsap.to(this.refs.text3Ref, 0.15, { autoAlpha: 1.0, delay: 6.0 });
    gsap.to(this.refs.text4Ref, 0.15, { autoAlpha: 1.0, delay: 8.0 });

    //remove base tracks
    globalState.baseAudioRef.updateTrack(1, false); 
    globalState.baseAudioRef.updateTrack(3, false); //richard
    globalState.baseAudioRef.updateTrack(4, false); //fly

    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.roadVideoPoster, 
      globalState.cdnUrl + preloadData.text1, 
      globalState.cdnUrl + preloadData.text2], this.preloadCallback);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentWillUnmount() {

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);
    gsap.killTweensOf(this.refs.text2Ref);
    gsap.killTweensOf(this.refs.text3Ref);
    gsap.killTweensOf(this.refs.text4Ref);
  }

  planeVideoEnded = () => {
    this.planeLandingVideoRef.hideVideo(0);
    this.planeLandingEndLoopVideoRef.showVideo(0);
    this.planeLandingEndLoopVideoRef.playVideo();
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
      showText(this.refs.text3Ref, "right", "top", globalState.textPosition.rightTop);
      showText(this.refs.text2Ref, "left", "top", globalState.textPosition.leftTop);
      showText(this.refs.text4Ref, "right", "bottom", globalState.textPosition.rightBottom);

    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
      hideText(this.refs.text3Ref, "right");
      hideText(this.refs.text2Ref, "left");
      hideText(this.refs.text4Ref, "right");
    }
  }

  isTransitioning = () => {
    this.planeLandingVideoRef.pauseVideo();
    this.planeLandingEndLoopVideoRef.pauseVideo();
  }



  render() {


    return (

      <>

        <link rel="prefetch" href="/intro/2" />
        {preloadData && (
        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.roadVideo} />
        )}

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/intro/2" prevLocation="/intro/"
          isTransitioning={this.isTransitioning} />

        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Intro" />

          <div className="text-wrapper">
            <div className="text text--left-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} width="294" height="102" style={{ marginLeft: "40px" }} onClick={this.toggleTextDisplay} alt="PINE POINT WAS THE FIRST PLACE I EVER WENT ALONE." />
            </div>
            <div className="text text--left-top" ref="text2Ref">
              <input type="image" src={globalState.cdnUrl + data.text2} width="349" height="100" style={{ marginLeft: "160px", marginTop: "152px" }} onClick={this.toggleTextDisplay} alt="I was 9, living in Yellowknife, and travelled there for a hockey tournament. Atom league, 10-and-unders." />
            </div>
            <div className="text text--right-top" ref="text3Ref">
              <input type="image" src={globalState.cdnUrl + data.text3} width="362" height="225" style={{ marginRight: "85px", marginTop: "30px" }} onClick={this.toggleTextDisplay} alt="I don’t remember much. The 45-minute plane ride in, those giant propellers out the window as we flew over Great Slave Lake, the rink’s blue lobby. My parents, telling me not to call so much. And that’s about it." />
            </div>
            <div className="text text--right-bottom" ref="text4Ref">
              <input type="image" src={globalState.cdnUrl + data.text4} width="327" height="91" style={{ marginRight: "5px", marginBottom: "100px" }} onClick={this.toggleTextDisplay} alt="This isn’t the plane, my team’s not on it. But it is Pine Point, about the time I was there." />
            </div>
          </div>

          {data.planeLandingEndLoopVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.planeLandingEndLoopVideo}
              poster={globalState.cdnUrl + data.planeLandingEndLoopVideoPoster} autoPlay={false} muted={true} loop={true} ref={div => this.planeLandingEndLoopVideoRef = div} fullscreenClass={'fullscreen-video hide'} globalState={globalState} />
            : ''
          }
          {data.planeLandingVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.planeLandingVideo}
              poster={globalState.cdnUrl + data.planeLandingVideoPoster} autoPlay={true} muted={false} loop={false} ended={this.planeVideoEnded} ref={div => this.planeLandingVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
          }

        </div>
        )}
      </>
    );
  }


};

export default IntroPage1
